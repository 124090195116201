<template>
     <b-container fluid="">
      <b-row>
         <b-col sm="12">
               <card>
                  <template v-slot:headerTitle>
                        <h4 class="card-title">lineawesome Icon</h4>
                   </template>
                  <template v-slot:body>
                     <p>Use Class <code>&lt;i class="las la-bell"&gt;&lt;/i&gt;</code></p>
                     <div class="row mt-3">
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-bell"></i>bell</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-phone-volume"></i>phone</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-radiation"></i>radiation</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-dog"></i>dog</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-fish"></i>fish</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-spider"></i>la-spider</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-angle-down"></i>angle-down</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-angle-left"></i>angle-left</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-angle-right"></i>angle-right</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-angle-up"></i>angle-up</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-caret-left"></i>caret-left</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-caret-right"></i>caret-right</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-download"></i>download</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-location-arrow"></i>location-arrow</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-share"></i>share</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-backward"></i>backward</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-play"></i>play</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-pause"></i>pause</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-sync"></i>sync</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-volume-down"></i>volume-down</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-volume-mute"></i>volume-mute</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-volume-off"></i>volume-off</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-volume-up"></i>volume-up</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="lab la-youtube"></i>youtube</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-car"></i>car</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-truck"></i>truck</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-tree"></i>tree</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="lab la-pinterest-p"></i>pinterest</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="lab la-java"></i>java</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-city"></i>city</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-edit"></i>edit</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-copy"></i>copy</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-cut"></i>cut</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-pen"></i>pen</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-tag"></i>tag</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-save"></i>save</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-tasks"></i>tasks</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-comment"></i>comment</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-video"></i>video</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-smile"></i>smile</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-smile"></i>smile</a>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-2">
                           <a class="iq-icons-list" href="#"><i class="las la-smile"></i>smile</a>
                        </div>
                        <div class="col-sm-12 text-center mt-3">
                           <a href="https://icons8.com/line-awesome" target="blank" class="btn btn-primary">View All Icon</a>
                        </div>
                     </div>
                  </template>
               </card>
         </b-col>
      </b-row>
   </b-container>
</template>
<script>
export default {
    name:'LineAwsome'
}
</script>